import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useCommandRequestV2, useQueryRequestV2 } from 'common/hooks'
import Card from 'common/components/dataDisplay/Card'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Button from 'common/components/general/Button'
import {
  CompanyForSaveResponse,
  CompanyStatus,
  getCompanyForSave,
  NumberOfEmployeesOptions,
  NumberOfStoresOptions,
  upsertCompany,
  UpsertCompanyRequest,
} from 'core'
import { useHistory, useParams } from 'react-router-dom'
import { Col, Row, Skeleton } from 'antd'
import FormikSelectField from 'common/components/dataEntry/formik/FormikSelectField'
import { useTranslation } from 'react-i18next'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import { FormikTreeSelect } from 'common/components/dataEntry/formik/FormikTreeSelectField'
import FormikTextAreaField from 'common/components/dataEntry/formik/FormikTextAreaField'
import generateFriendlyUrl from '../../common/generateFriendlyUrl'
import CancelButton from '../../common/components/dataEntry/components/CancelButton'
import RoutePaths from '../../common/routePaths'

const ManageCompanies = () => {
  const { id } = useParams<any>()
  const history = useHistory()
  const { t } = useTranslation()
  const translate = (key: string) => t(`dashboard.companies.manage.${key}`)

  const { data: company, isLoading: isLoadingForSaveQuery } = useQueryRequestV2<CompanyForSaveResponse>({
    initialState: {
      name: '',
      friendlyUrl: '',
      identificationCode: '',
      phoneNumber: '',
    } as CompanyForSaveResponse,
    queryFn: () => getCompanyForSave(id),
  })

  const { sendCommand, isLoading: isSendingUpsertCommand } = useCommandRequestV2(upsertCompany)

  return (
    <Skeleton loading={isLoadingForSaveQuery}>
      <Formik
        isInitialValid={false}
        validationSchema={schema(t)}
        initialValues={{ ...company, isVerified: company?.isVerified ? 'true' : '' }!}
        onSubmit={async ({ seoTitle, seoDescription, isVerified, ...v }) => {
          const { completed } = await sendCommand({
            ...v,
            isVerified: !!isVerified,
            seoTitle: !seoTitle ? v.name : seoTitle,
            seoDescription: !seoDescription ? v.description : seoDescription,
          } as UpsertCompanyRequest)
          if (completed) {
            history.goBack()
          }
        }}
      >
        {({
          handleSubmit,
          isValid,
          setValues,
          setFieldValue,
          values: {
            logo,
            bannerImage,
            serviceIds,
            industryIds,
            availableServices,
            availableIndustries,
            countryId,
            availableCountries,
            regionId,
            availableRegions,
            ...values
          },
        }) => (
          <form onSubmit={handleSubmit}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card title={t('dashboard.products.info')} className="h-100">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Input
                        required
                        name="name"
                        label={translate('name')}
                        onBlur={(e: any) => {
                          if (id) return
                          setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <Input required name="friendlyUrl" label={t('dashboard.blog.categories.friendly_url')} />
                    </Col>
                    <Col span={12}>
                      <Input required name="identificationCode" label={translate('identification_code')} />
                    </Col>
                    <Col span={12}>
                      <Input required name="phoneNumber" label={translate('phone_number')} />
                    </Col>
                    <Col span={12}>
                      <Input name="email" label={translate('email')} />
                    </Col>
                    <Col span={12}>
                      <Input name="secondaryPhoneNumber" label={translate('secondary_phone_number')} />
                    </Col>
                    <Col span={12}>
                      <FormikSelectField name="status" label={t('dashboard.store_settings.edit_page_form.status')}>
                        <option value={CompanyStatus.Draft}>Draft</option>
                        <option value={CompanyStatus.InReview}>In review</option>
                        <option value={CompanyStatus.Published}>Published</option>
                        <option value={CompanyStatus.Archived}>Archived</option>
                      </FormikSelectField>
                    </Col>
                    <Col span={12}>
                      <FormikSelectField name="isVerified" label={translate('is_verified')}>
                        <option value={''}>No</option>
                        <option value={'true'}>Yes</option>
                      </FormikSelectField>
                    </Col>
                    <Col span={24}>
                      <FormikTreeSelect
                        multiple
                        showArrow
                        showSearch
                        allowClear
                        treeCheckable
                        treeCheckStrictly
                        treeDefaultExpandAll
                        name="industryIds"
                        label={'Industries'}
                        placeholder={'Select industries'}
                        treeData={availableIndustries}
                        defaultValue={industryIds}
                        onChange={(selections: { [key: string]: { value: string } }) =>
                          setValues((v) => ({ ...v, industryIds: Object.values(selections).map(({ value }) => value) }))
                        }
                      />
                    </Col>
                    <Col span={24}>
                      <Uploader
                        optimizedResize
                        fieldName="logo"
                        name="logo"
                        label={translate('logo')}
                        initialValues={[logo ?? '']}
                        mode="single"
                        maxUploads={1}
                        resizeToWidth={1940}
                        resizeToHeight={1940}
                      />
                    </Col>
                    <Col span={24}>
                      <Uploader
                        optimizedResize
                        fieldName="bannerImage"
                        name="bannerImage"
                        label={translate('banner_image')}
                        initialValues={[bannerImage ?? '']}
                        mode="single"
                        maxUploads={1}
                        resizeToWidth={1940}
                        resizeToHeight={1940}
                      />{' '}
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={12}>
                <Card title={translate('details')} className="h-100">
                  <Row gutter={16}>
                    <Col span={24}>
                      <Input name="coordinates" label={translate('google_map_coordinates')} />
                    </Col>
                    <Col span={24}>
                      <Input name="googleMapsEmbed" label={translate('google_maps_embed')} />
                    </Col>
                    <Col span={24}>
                      <Input name="time" label={translate('time')} />
                    </Col>
                    <Col span={12}>
                      <FormikSelectField
                        name="numberOfStores"
                        defaultValue={company?.numberOfStores ? [company?.numberOfStores] : []}
                        label={translate('number_of_stores')}
                      >
                        <option value={NumberOfStoresOptions.Undefined} placeholder={translate('number_of_stores')}>
                          Undefined
                        </option>
                        <option value={NumberOfStoresOptions.Zero}>0</option>
                        <option value={NumberOfStoresOptions.One}>1</option>
                        <option value={NumberOfStoresOptions.TwoToFive}>2-5</option>
                        <option value={NumberOfStoresOptions.SixToTen}>6-10</option>
                        <option value={NumberOfStoresOptions.ElevenToTwenty}>11-20</option>
                        <option value={NumberOfStoresOptions.TwentyOneToFifty}>21-50</option>
                        <option value={NumberOfStoresOptions.MoreThanFifty}>More than 50</option>
                      </FormikSelectField>
                    </Col>
                    <Col span={12}>
                      <FormikSelectField
                        name="numberOfEmployees"
                        defaultValue={company?.numberOfEmployees ? [company?.numberOfEmployees] : []}
                        label={translate('number_of_employees')}
                      >
                        <option value={NumberOfEmployeesOptions.Undefined} placeholder={translate('number_of_stores')}>
                          Undefined
                        </option>
                        <option value={NumberOfEmployeesOptions.OneToTen}>1-10</option>
                        <option value={NumberOfEmployeesOptions.ElevenToTwenty}>11-20</option>
                        <option value={NumberOfEmployeesOptions.TwentyOneToFifty}>21-50</option>
                        <option value={NumberOfEmployeesOptions.FiftyOneToOneHundred}>51-100</option>
                        <option value={NumberOfEmployeesOptions.OneHundredAndOneToTwoHundred}>101-200</option>
                        <option value={NumberOfEmployeesOptions.TwoHundredAndOneToThreeHundred}>201-300</option>
                        <option value={NumberOfEmployeesOptions.MoreThanThreeHundred}>More than 300</option>
                      </FormikSelectField>
                    </Col>
                    <Col span={12}>
                      <Input name="website" label={translate('website')} />
                    </Col>
                    <Col span={12}>
                      <Input name="yearFounded" type="number" label={translate('year_founded')} />
                    </Col>
                    <Col span={12}>
                      <Input name="address" label={translate('address')} />
                    </Col>
                    <Col span={12}>
                      <Input name="postalCode" label={t('dashboard.orders.order_details.postal_code')} />
                    </Col>
                    <Col span={8}>
                      <FormikSelectField
                        showArrow
                        name="countryId"
                        label={translate('country_id')}
                        placeholder={t('dashboard.store_settings.general_settings_form.country')}
                        defaultValue={countryId ? [countryId] : []}
                      >
                        {availableCountries?.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </FormikSelectField>
                    </Col>
                    <Col span={8}>
                      <FormikSelectField
                        showArrow
                        name="regionId"
                        label={translate('region_id')}
                        placeholder={t('dashboard.store_settings.general_settings_form.country')}
                        defaultValue={regionId ? [regionId] : []}
                      >
                        {availableRegions
                          ?.filter(({ countryId: id }) => !countryId || id === countryId)
                          ?.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </FormikSelectField>
                    </Col>
                    <Col span={8}>
                      <Input name="city" label={translate('city')} />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={24}>
                <Card title={translate('social_settings.title')} className="h-100">
                  <Row gutter={16}>
                    {company?.socialSettings &&
                      Object.keys(company.socialSettings).map(
                        (key) =>
                          key !== 'tikTok' && (
                            <Col key={key} span={8}>
                              <Input name={`socialSettings.${key}`} label={translate(`social_settings.${key}`)} />
                            </Col>
                          ),
                      )}
                  </Row>
                </Card>
              </Col>

              <Col span={24}>
                <Card title={translate('description')}>
                  <FormikHtmlEditorField name="description" value={company?.description} />
                </Card>
              </Col>

              <Col span={24}>
                <Card title={translate('services_summary')}>
                  <FormikHtmlEditorField name="servicesSummary" value={company?.servicesSummary} />
                </Card>
              </Col>

              <Col span={24}>
                <Card title={translate('why_us')}>
                  <FormikHtmlEditorField name="whyUs" value={company?.whyUs} />
                </Card>
              </Col>

              <Col span={24}>
                <Card title={translate('vision')}>
                  <FormikHtmlEditorField name="vision" value={company?.vision} />
                </Card>
              </Col>

              <Col span={24}>
                <Card title={translate('advantages')}>
                  <FormikHtmlEditorField name="advantages" value={company?.advantages} />
                </Card>
              </Col>

              <Col span={24}>
                <Card title={translate('client_benefits')}>
                  <FormikHtmlEditorField name="clientBenefits" value={company?.clientBenefits} />
                </Card>
              </Col>

              <Col span={24}>
                <Card title={t('dashboard.store_settings.site_settings_form.seo')}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Input name="seoTitle" label={t('seoTitle')} />
                    </Col>

                    <Col span={24}>
                      <FormikTextAreaField name="seoDescription" label={t('seoDescription')} />
                    </Col>
                    <Col span={24}>
                      <Uploader
                        optimizedResize
                        fieldName="seoImage"
                        name="seoImage"
                        label={t('seoImage')}
                        initialValues={[values.seoImage ?? '']}
                        mode="single"
                        maxUploads={1}
                        resizeToWidth={1940}
                        resizeToHeight={1940}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={24} className="text-center">
                <Button
                  icon={`far fa-${id ? 'pencil' : 'plus'}`}
                  htmlType="submit"
                  type="primary"
                  loading={isSendingUpsertCommand}
                  className={'mr-16'}
                  disabled={!isValid}
                >
                  {t(id ? 'general.edit' : 'dashboard.add')}
                </Button>

                <CancelButton backUrl={RoutePaths.COMPANIES} title={'Cancel'} />
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </Skeleton>
  )
}

const schema = (t: (key: string) => string) =>
  Yup.object({
    id: Yup.string().nullable().notRequired(),
    storeId: Yup.string().nullable().notRequired(),
    name: Yup.string().typeError(t('general.required')).required(t('general.required')),
    identificationCode: Yup.string().typeError(t('general.required')).required(t('general.required')),
    phoneNumber: Yup.string().typeError(t('general.required')).required(t('general.required')),
    friendlyUrl: Yup.string().typeError(t('general.required')).required(t('general.required')),
    logo: Yup.string().nullable().notRequired(),
    description: Yup.string().nullable().notRequired(),
    vision: Yup.string().nullable().notRequired(),
    advantages: Yup.string().nullable().notRequired(),
    website: Yup.string().nullable().notRequired(),
    time: Yup.string().nullable().notRequired(),
    coordinates: Yup.string().nullable().notRequired(),
    servicesSummary: Yup.string().nullable().notRequired(),
    clientBenefits: Yup.string().nullable().notRequired(),
    socialSettings: Yup.object({
      linkedin: Yup.string().nullable().notRequired(),
      facebook: Yup.string().nullable().notRequired(),
      youtube: Yup.string().nullable().notRequired(),
      instagram: Yup.string().nullable().notRequired(),
      twitter: Yup.string().nullable().notRequired(),
      pinterest: Yup.string().nullable().notRequired(),
    }).notRequired(),
    address: Yup.string().nullable().notRequired(),
    city: Yup.string().nullable().notRequired(),
    countryId: Yup.string().nullable().notRequired(),
    regionId: Yup.string().nullable().notRequired(),
    email: Yup.string().email().nullable().notRequired(),
    numberOfStores: Yup.number().nullable().notRequired(),
    numberOfEmployees: Yup.number().nullable().notRequired(),
  })

export default ManageCompanies
