import { adminHttpClient } from '../../../commons/adminHttpClient'
import { NamedItem } from '../../../commons/namedItem'

export interface RequestMetadata {
  ip: string
  browser: string
  device: string
  os: string
}

export interface Order {
  id: string
  status: number
  paymentMethod: NamedItem
  currency: NamedItem
  totalAmount: number
  totalPrice: number
  notes: string
  firstName: string
  deliveryCost: number
  lastName: string
  email: string
  phoneNumber: string
  address: string
  city: string
  country: string
  orderNumber: string
  items: OrderItem[]
  totalItemsCount: number
  aptSuite: string
  postalCode: string
  shippingInstructions: string
  subTotalPrice: number
  createdAt?: string
  discountCode?: string
  discountValue: number
  hasOnlyDigitalProducts: boolean
  requestMetadata?: RequestMetadata
  insuranceCost: number
}

export interface OrderItem {
  productId: string
  productName: string
  quantity: number
  finalPrice: number
  subTotal: number
  selectedAttributes: SelectedAttributeValue[]
}

export interface SelectedAttributeValue {
  attributeName: string
  attributeValueName: string
  attributeValueId: string
}

export interface OrderDetails {
  order: Order
  availableStatuses: NamedItem[]
}

const getOrderDetails = (id: string): Promise<OrderDetails> => {
  return adminHttpClient.get(`/admindashboard/orders/${id}`)
}

export default getOrderDetails
